import React, { useEffect, useState } from "react";
import { Dropdown, Form} from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHttpClient } from "../../util/http-hook";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import authService from "../../services/authService";
import lista_province from "../../services/province.json";
import lista_comuni from "../../services/comuni.json";

const amministrazioneSchema = Yup.object().shape({
  denominazione: Yup.string().required("Richiesto"),
  ref_tipologia: Yup.number().required("Richiesto"),
});

const InformazioniAmministrazione = (props) => {
  const history = useHistory();
  const token = authService.getCurrentToken();
  const { sendRequest } = useHttpClient();
  const [tipologie, setTipologie] = useState();
  const [comuni, setComuni] = useState();

  const handleChangeProvincia = (data) => {
    informazioni.setFieldValue("provincia", data.value);
    lista_comuni.forEach((provincia) => {
      if (provincia.id === data.value) {
        setComuni(provincia.comuni);
      }
    });
  };

  useEffect(() => {
    lista_comuni.forEach((provincia) => {
      if (provincia.id === props.info.provincia) {
        setComuni(provincia.comuni);
      }
    });

    const ottieniTipologie = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/tipologie/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const tipologie_response = [];
        responseData.forEach((tipologia) => {
          tipologie_response.push({
            key: tipologia.id_tipologia,
            value: tipologia.id_tipologia,
            text: tipologia.descrizione_tipologia,
          });
        });
        setTipologie(tipologie_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    ottieniTipologie();
  }, [props.info.provincia, sendRequest, token]);

  const informazioni = useFormik({
    initialValues: {
      denominazione: props.info.denominazione,
      ref_tipologia: props.info.ref_tipologia,
      indirizzo_notifica: props.info.indirizzo_notifica_cartacea,
      citta: props.info.citta,
      provincia: props.info.provincia,
      cap: props.info.cap,
      responsabile_1: props.info.nome_resp_1,
      telefono_resp_1: props.info.telefono_resp_1,
      email_resp_1: props.info.email_resp_1,
      responsabile_2: props.info.nome_resp_2,
      telefono_resp_2: props.info.telefono_resp_2,
      email_resp_2: props.info.email_resp_2,
      pec_1: props.info.indirizzo_pec_1,
      pec_2: props.info.indirizzo_pec_2,
      partita_iva: props.info.partita_iva
    },
    validationSchema: amministrazioneSchema,
    onSubmit: async (values) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL +
            `/amministrazioni/modificaAmministrazione/${props.info.id_amministrazione}`,
          "POST",
          JSON.stringify({
            denominazione: values.denominazione,
            ref_tipologia: values.ref_tipologia,
            indirizzo_notifica_cartacea: values.indirizzo_notifica,
            citta: values.citta,
            provincia: values.provincia,
            cap: values.cap,
            nome_resp_1: values.responsabile_1,
            telefono_resp_1: values.telefono_resp_1,
            email_resp_1: values.email_resp_1,
            nome_resp_2: values.responsabile_2,
            telefono_resp_2: values.telefono_resp_2,
            email_resp_2: values.email_resp_2,
            indirizzo_pec_1: values.pec_1,
            indirizzo_pec_2: values.pec_2,
            partita_iva: values.partita_iva
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Operazione completata correttamente",
          text: responseData,
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        }).then(() => history.go(0));
      }
    },
  });

  return (
    <React.Fragment>
      <div className="row">
        <div className="row ml-auto mr-3 mb-2 mb-md-0 ">
          <label
            role="button"
            className=""
            style={{ fontWeight: 600 }}
            onClick={informazioni.handleSubmit}
          >
            <FontAwesomeIcon icon={faSave} /> Salva modifiche
          </label>
        </div>
      </div>
      <div>
        <div style={{ fontSize: "110%" }}>
          <Form>
            <Form.Group widths="equal">
              <Form.Input
                required
                label="Denominazione"
                id="denominazione"
                value={informazioni.values.denominazione}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
              <Form.Field required>
                <label>Tipologia</label>
                <Dropdown
                  error={
                    informazioni.errors.tipologia &&
                    informazioni.touched.tipologia
                  }
                  placeholder="Tipologia"
                  fluid
                  selection
                  value={informazioni.values.ref_tipologia}
                  options={tipologie}
                  onChange={(e, data) =>
                    informazioni.setFieldValue("ref_tipologia", data.value)
                  }
                  onBlur={() => informazioni.setFieldTouched("ref_tipologia")}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="P.iva"
                id="partita_iva"
                value={informazioni.values.partita_iva}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
              <Form.Input
                label="Indirizzo notifica cartacea"
                id="indirizzo_notifica"
                value={informazioni.values.indirizzo_notifica}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field >
                <label>Provincia</label>
                <Dropdown
                  error={
                    informazioni.errors.provincia &&
                    informazioni.touched.provincia
                  }
                  placeholder="Provincia"
                  fluid
                  selection
                  search
                  value={informazioni.values.provincia}
                  options={lista_province}
                  onChange={(e, data) => handleChangeProvincia(data)}
                  onBlur={() => informazioni.setFieldTouched("provincia")}
                />
              </Form.Field>
              <Form.Field >
                <label>Città</label>
                <Dropdown
                  error={
                    informazioni.errors.citta && informazioni.touched.citta
                  }
                  placeholder="Citta"
                  fluid
                  selection
                  search
                  value={informazioni.values.citta}
                  options={comuni}
                  onChange={(e, data) =>
                    informazioni.setFieldValue("citta", data.value)
                  }
                  onBlur={() => informazioni.setFieldTouched("citta")}
                />
              </Form.Field>
              <Form.Input
                label="CAP"
                id="cap"
                value={informazioni.values.cap}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Input
                label="Responsabile 1"
                id="responsabile_1"
                value={informazioni.values.responsabile_1}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
              <Form.Input
                label="Telefono responsabile 1"
                id="telefono_resp_1"
                value={informazioni.values.telefono_resp_1}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
              <Form.Input
                label="Email responsabile 1"
                id="email_resp_1"
                value={informazioni.values.email_resp_1}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Responsabile 2"
                id="responsabile_2"
                value={informazioni.values.responsabile_2}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
              <Form.Input
                label="Telefono responsabile 2"
                id="telefono_resp_2"
                value={informazioni.values.telefono_resp_2}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
              <Form.Input
                label="Email responsabile 2"
                id="email_resp_2"
                value={informazioni.values.email_resp_2}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Indirizzo PEC 1"
                id="pec_1"
                value={informazioni.values.pec_1}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
              <Form.Input
                label="Indirizzo PEC 2"
                id="pec_2"
                value={informazioni.values.pec_2}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
            </Form.Group>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InformazioniAmministrazione;
