import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Icon } from "semantic-ui-react";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";
import * as Yup from "yup";
import authService from "../../services/authService";
import lista_province from "../../services/province.json";
import lista_comuni from "../../services/comuni.json";

const amministrazioneSchema = Yup.object().shape({
  denominazione: Yup.string().required("Richiesto"),
  tipologia: Yup.number().required("Richiesto"),
});

const AggiungiAmministrazione = (props) => {
  const { sendRequest, isLoading } = useHttpClient();
  const token = authService.getCurrentToken();
  const [operazioni, setOperazioni] = useState();
  const [tipologie, setTipologie] = useState();
  const [comuni, setComuni] = useState();

  const handleChangeProvincia = (data) => {
    formik.setFieldValue("provincia", data.value);
    lista_comuni.forEach((provincia) => {
      if (provincia.id === data.value) {
        setComuni(provincia.comuni);
      }
    });
  };

  useEffect(() => {
    const ottieniOperazioni = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/operazioni/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const operazioni_response = [];
        responseData.forEach((operazione) => {
          if (operazione.id_operazione !== 4) {
            operazioni_response.push({
              key: operazione.id_operazione,
              value: operazione.id_operazione,
              text: operazione.descrizione_operazione,
            });
          }
        });
        setOperazioni(operazioni_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    const ottieniTipologie = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/tipologie/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const tipologie_response = [];
        responseData.forEach((tipologia) => {
          tipologie_response.push({
            key: tipologia.id_tipologia,
            value: tipologia.id_tipologia,
            text: tipologia.descrizione_tipologia,
          });
        });

        setTipologie(tipologie_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    ottieniTipologie();
    ottieniOperazioni();
  }, [sendRequest, token]);

  

  const formik = useFormik({
    initialValues: {
      denominazione: "",
      tipologia: "",
      operazioni_permesse: [],
      indirizzo_notifica: "",
      citta: "",
      provincia: "",
      cap: "",
      nome_responsabile_1: "",
      telefono_responsabile_1: "",
      mail_responsabile_1: "",
      nome_responsabile_2: "",
      telefono_responsabile_2: "",
      mail_responsabile_2: "",
      indirizzo_pec_1: "",
      indirizzo_pec_2: "",
      partita_iva: ""
    },
    validationSchema: amministrazioneSchema,
    onSubmit: async (values) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL +
            "/amministrazioni/aggiungiAmministrazione/",
          "POST",
          JSON.stringify({
            denominazione: values.denominazione,
            tipologia: values.tipologia,
            operazioni_permesse: values.operazioni_permesse,
            indirizzo_notifica: values.indirizzo_notifica,
            citta: values.citta,
            provincia: values.provincia,
            cap: values.cap,
            nome_responsabile_1: values.nome_responsabile_1,
            telefono_responsabile_1: values.telefono_responsabile_1,
            mail_responsabile_1: values.mail_responsabile_1,
            nome_responsabile_2: values.nome_responsabile_2,
            telefono_responsabile_2: values.telefono_responsabile_2,
            mail_responsabile_2: values.mail_responsabile_2,
            indirizzo_pec_1: values.indirizzo_pec_1,
            indirizzo_pec_2: values.indirizzo_pec_2,
            partita_iva: values.partita_iva
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Operazione completata!",
          text: "Contatto inserito correttamente!",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          props.aggiungiAmministrazione(responseData);
          props.handleClose();
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    },
  });
  return (
    <div>
      {!isLoading && operazioni && (
        <Form>
          <Form.Group widths="equal">
            <Form.Input
              required
              id="denominazione"
              error={
                formik.errors.denominazione && formik.touched.denominazione
              }
              fluid
              label="Denominazione"
              placeholder="Denominazione"
              value={formik.values.denominazione}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Field required>
              <label>Tipologia</label>
              <Dropdown
                error={formik.errors.tipologia && formik.touched.tipologia}
                placeholder="Tipologia"
                fluid
                selection
                options={tipologie}
                onChange={(e, data) =>
                  formik.setFieldValue("tipologia", data.value)
                }
                onBlur={() => formik.setFieldTouched("tipologia")}
              />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Operazioni permesse</label>
            <Dropdown
              placeholder="Operazioni"
              fluid
              multiple
              selection
              options={operazioni}
              onChange={(e, data) =>
                formik.setFieldValue("operazioni_permesse", data.value)
              }
            />
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              id="partita_iva"
              label="P. Iva"
              placeholder="p.iva"
              value={formik.values.partita_iva}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Input
              fluid
              error={
                formik.errors.indirizzo_notifica &&
                formik.touched.indirizzo_notifica
              }
              id="indirizzo_notifica"
              label="Indirizzo di notifica cartacea"
              placeholder="Indirizzo"
              value={formik.values.indirizzo_notifica}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field >
              <label>Provincia</label>
              <Dropdown
                error={formik.errors.provincia && formik.touched.provincia}
                placeholder="Provincia"
                fluid
                selection
                search
                options={lista_province}
                onChange={(e, data) => handleChangeProvincia(data)}
                onBlur={() => formik.setFieldTouched("provincia")}
              />
            </Form.Field>
            <Form.Field >
              <label>Città</label>
              <Dropdown
                error={formik.errors.citta && formik.touched.citta}
                placeholder="Citta"
                fluid
                selection
                search
                options={comuni}
                onChange={(e, data) =>
                  formik.setFieldValue("citta", data.value)
                }
                onBlur={() => formik.setFieldTouched("citta")}
              />
            </Form.Field>
            <Form.Input
              fluid
              error={formik.errors.cap && formik.touched.cap}
              id="cap"
              label="CAP"
              placeholder="CAP"
              value={formik.values.cap}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              id="nome_responsabile_1"
              label="Nome responsabile 1"
              placeholder="Nome"
              value={formik.values.nome_responsabile_1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Input
              fluid
              id="telefono_responsabile_1"
              label="Recapito responsabile 1"
              placeholder="Recapito"
              value={formik.values.telefono_responsabile_1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Input
              fluid
              id="mail_responsabile_1"
              label="Email responsabile 1"
              placeholder="Email"
              value={formik.values.mail_responsabile_1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              id="nome_responsabile_2"
              label="Nome responsabile 2"
              placeholder="Nome"
              value={formik.values.nome_responsabile_2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Input
              fluid
              id="telefono_responsabile_2"
              label="Recapito responsabile 2"
              placeholder="Recapito"
              value={formik.values.telefono_responsabile_2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Input
              fluid
              id="mail_responsabile_2"
              label="Email responsabile 2"
              placeholder="Email"
              value={formik.values.mail_responsabile_2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              id="indirizzo_pec_1"
              label="PEC di notifica 1"
              placeholder="PEC"
              value={formik.values.indirizzo_pec_1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <Form.Input
              fluid
              id="indirizzo_pec_2"
              label="PEC di notifica 2"
              placeholder="PEC"
              value={formik.values.indirizzo_pec_2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Group>
          <Button
            type="submit"
            icon
            labelPosition="left"
            color="green"
            floated="right"
            onClick={formik.handleSubmit}
          >
            <Icon name="save" />
            Salva
          </Button>
        </Form>
      )}
    </div>
  );
};

export default AggiungiAmministrazione;
