import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";
import * as Yup from "yup";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Dropdown, Form, Modal } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import authService from "../../services/authService";

const finanziamentiSchema = Yup.object().shape({
  rataMensile: Yup.number().required("Richiesto"),
  nettoRicavo: Yup.number().required("Richiesto"),
});

const InfoFinanziamento = (props) => {
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();
  const [istituti, setIstituti] = useState();
  const [dataDecorrenza, setDataDecorrenza] = useState(
    props.finanziamento.data_decorrenza
  );
  const [dataScadenza, setDataScadenza] = useState(
    props.finanziamento.data_scadenza_finanziamento
  );
  const history = useHistory();

  useEffect(() => {
    const ottieniIstituti = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/istituti/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const istituti_response = [];
        responseData.forEach((istituto) => {
          istituti_response.push({
            key: istituto.id_istituto,
            value: istituto.id_istituto,
            text: istituto.denominazione,
          });
        });
        setIstituti(istituti_response);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };

    ottieniIstituti();
  }, [sendRequest, token]);

  const eliminaFinanziamento = async () => {
    Swal.fire({
      icon: "question",
      title: "Cancellare il finanziamento?",
      text: "Cancellando il finanziamento tutte le opportunità \n e le statistiche di questo finanziamento  verranno cancellate, confermare?",
      confirmButtonText: "Elimina",
      showCancelButton: true,
      cancelButtonText: "Annulla",
      confirmButtonColor: "red",
    }).then(async (value) => {
      if (value.isConfirmed) {
        try {
          await sendRequest(
            process.env.REACT_APP_BASE_URL +
              `/finanziamenti/eliminaFinanziamento/${props.finanziamento.id_finanziamento}`,
            "POST",
            null,
            {
              Authorization: "Bearer " + token,
            }
          );
          Swal.fire({
            icon: "success",
            title: "Finanziamento eliminato correttamente!",
          });
          props.handleClose();
          history.go(0);
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Qualcosa è andato storto...",
            text: error,
          });
        }
      }
    });
  };

  const archiviaFinanziamento = async () => {
    Swal.fire({
      icon: "question",
      title: "Cancellare il finanziamento?",
      text: "Archiviando il finanziamento tutte le opportunità \n e le statistiche di questo finanziamento  verranno cancellate, confermare?",
      confirmButtonText: "Archivia",
      showCancelButton: true,
      cancelButtonText: "Annulla",
      confirmButtonColor: "red",
    }).then(async (value) => {
      if (value.isConfirmed) {
        try {
          await sendRequest(
            process.env.REACT_APP_BASE_URL +
              `/finanziamenti/${props.finanziamento.id_finanziamento}/archivia`,
            "GET",
            null,
            {
              Authorization: "Bearer " + token,
            }
          );
          Swal.fire({
            icon: "success",
            title: "Finanziamento archiviato correttamente!",
          });
          props.handleClose();
          history.push(`/dettagliContatto/${props.finanziamento.ref_contatto}`);
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Qualcosa è andato storto...",
            text: error,
          });
        }
      }
    });
  };

  const formikFinanziamento = useFormik({
    initialValues: {
      rataMensile: props.finanziamento.rata_stipulata,
      nettoRicavo: props.finanziamento.netto_ricavo,
      ref_societa_estinta: props.finanziamento.ref_societa_estinta,
      importoEstinzione: props.finanziamento.estinzione_anticipata,
      durata_mesi: props.finanziamento.durata_mesi,
    },
    validationSchema: finanziamentiSchema,
    onSubmit: async (values) => {
      try {
        await sendRequest(
          process.env.REACT_APP_BASE_URL +
            "/finanziamenti/modificaFinanziamento",
          "PATCH",
          JSON.stringify({
            id_finanziamento: props.finanziamento.id_finanziamento,
            rata_stipulata: values.rataMensile,
            netto_ricavo: values.nettoRicavo,
            durata_mesi: values.durata_mesi,
            estinzione_anticipata: values.importoEstinzione,
            ref_societa_estinta: values.ref_societa_estinta,
            data_decorrenza: dataDecorrenza,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Finanziamento modificato correttamente",
        }).then(() => {
          props.handleClose();
          history.go(0);
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    },
  });

  return (
    <Modal
      closeIcon={{
        style: { top: "1.0535rem", right: "1rem" },
        color: "black",
        name: "close",
      }}
      onClose={() => props.handleClose()}
      open
      style={{ position: "relative", height: "auto" }}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {props.finanziamento.operazione.descrizione_operazione}
        <div className="float-right mr-5" style={{ fontSize: "70%" }}>
          <label className="mr-3" type="button" onClick={eliminaFinanziamento}>
            <FontAwesomeIcon icon={faTrash} /> Elimina
          </label>
          {!props.finanziamento.archiviato && (
            <>
              <label
                className="mr-3"
                type="button"
                onClick={archiviaFinanziamento}
              >
                <FontAwesomeIcon icon={faArchive} /> Archivia
              </label>
              <label
                className="mr-0"
                type="button"
                onClick={formikFinanziamento.handleSubmit}
              >
                <FontAwesomeIcon icon={faEdit} /> Modifica
              </label>
            </>
          )}
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div style={{ fontSize: "110%" }}>
            <Form>
              <fieldset disabled={props.finanziamento.archiviato}>
                <Form.Input
                  label="Cliente"
                  value={
                    props.finanziamento.contatto.cognome +
                    " " +
                    props.finanziamento.contatto.nome
                  }
                  readOnly
                />
                <Form.Group widths="equal">
                  {props.finanziamento.collaboratore && (
                    <Form.Input
                      label="Collaboratore"
                      value={
                        props.finanziamento.collaboratore.cognome +
                        " " +
                        props.finanziamento.collaboratore.nome
                      }
                      readOnly
                    />
                  )}
                  {props.finanziamento.istituto_finanziario && istituti && (
                    <Form.Field required>
                      <label>Società estinta</label>
                      <Dropdown
                        disabled={props.finanziamento.archiviato}
                        error={
                          formikFinanziamento.errors.ref_societa_estinta &&
                          formikFinanziamento.touched.ref_societa_estinta
                        }
                        value={formikFinanziamento.values.ref_societa_estinta}
                        placeholder="Società"
                        fluid
                        selection
                        search
                        options={istituti}
                        onChange={(e, data) =>
                          formikFinanziamento.setFieldValue(
                            "ref_societa_estinta",
                            data.value
                          )
                        }
                        onBlur={() =>
                          formikFinanziamento.setFieldTouched(
                            "ref_societa_estinta"
                          )
                        }
                      />
                    </Form.Field>
                  )}
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field required>
                    <label style={{ fontWeight: 700 }}>Data decorrenza</label>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_decorrenza"
                        value={dataDecorrenza}
                        onChange={setDataDecorrenza}
                        format={"DD-MM-YYYY"}
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label style={{ fontWeight: 700 }}>Data scadenza</label>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={MomentUtils}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_scadenza"
                        value={dataScadenza}
                        onChange={setDataScadenza}
                        format={"DD-MM-YYYY"}
                        readOnly
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    required
                    label="Rata stipulata"
                    id="rataMensile"
                    value={formikFinanziamento.values.rataMensile}
                    onChange={formikFinanziamento.handleChange}
                    onBlur={formikFinanziamento.handleBlur}
                    error={
                      formikFinanziamento.errors.rataMensile &&
                      formikFinanziamento.touched.rataMensile
                    }
                  />
                  <Form.Input
                    required
                    label="Durata in mesi"
                    id="durata_mesi"
                    value={formikFinanziamento.values.durata_mesi}
                    onChange={formikFinanziamento.handleChange}
                    onBlur={formikFinanziamento.handleBlur}
                    error={
                      formikFinanziamento.errors.durata_mesi &&
                      formikFinanziamento.touched.durata_mesi
                    }
                  />
                  {props.finanziamento.collaboratore && (
                    <Form.Input
                      label="Montante Lordo"
                      value={props.finanziamento.montante_lordo}
                      readOnly
                    />
                  )}
                </Form.Group>
                {props.finanziamento.collaboratore && (
                  <Form.Group widths="equal">
                    <Form.Input
                      required
                      label="Netto ricavo"
                      id="nettoRicavo"
                      value={formikFinanziamento.values.nettoRicavo}
                      onChange={formikFinanziamento.handleChange}
                      onBlur={formikFinanziamento.handleBlur}
                      error={
                        formikFinanziamento.errors.nettoRicavo &&
                        formikFinanziamento.touched.nettoRicavo
                      }
                    />
                    <Form.Input
                      required
                      label="Estinzione anticipata"
                      id="importoEstinzione"
                      value={formikFinanziamento.values.importoEstinzione}
                      onChange={formikFinanziamento.handleChange}
                      onBlur={formikFinanziamento.handleBlur}
                      error={
                        formikFinanziamento.errors.importoEstinzione &&
                        formikFinanziamento.touched.importoEstinzione
                      }
                    />
                    <Form.Input
                      label="Netto alla mano"
                      value={props.finanziamento.netto_mano}
                      readOnly
                    />
                  </Form.Group>
                )}
              </fieldset>
            </Form>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default InfoFinanziamento;
