import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal } from "semantic-ui-react";

import FinanziamentiItem from "./FinanziamentiItem";
import AggiungiFinanziamento from "../../../shared/components/AggiungiFinanziamento";
import AggiungiFinanziamentoEssereSingolo from "../../../shared/components/AggiungiFinanziamentoEssereSingolo";

const FinanziamentiList = (props) => {
  const [open, setOpen] = React.useState(false);
  const [visibile, setVisibile] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleVisibile = () => {
    setVisibile(false);
  };

  const aggiungiFinanziamento = (finanziamento) => {
    props.info.push(finanziamento);
  };
  return (
    <React.Fragment>
      {visibile && (
        <AggiungiFinanziamentoEssereSingolo
          handleClose={handleVisibile}
          ref_contatto={props.id_contatto}
          aggiungiFinanziamento={aggiungiFinanziamento}
        />
      )}

      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        style={{ position: "relative", height: "auto" }}
      >
        <Modal.Header className="text-center">
          Aggiungi nuovo finanziamento
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <AggiungiFinanziamento
              id_contatto={props.id_contatto}
              cognome={props.cognome}
              nome={props.nome}
              aggiungiFinanziamento={aggiungiFinanziamento}
              closeModel={handleClose}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <div>
        <div className="row">
          <div className="row ml-auto mr-3 mb-2 mb-md-0 ">
            <label
              role="button"
              className="mr-3"
              style={{ fontWeight: 600 }}
              onClick={() => setVisibile(true)}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Nuovo finanziamento in
              essere
            </label>
            <label
              role="button"
              style={{ fontWeight: 600 }}
              onClick={() => setOpen(true)}
            >
              <FontAwesomeIcon icon={faPlusCircle} /> Nuovo finanziamento
            </label>
          </div>
        </div>
        {props.info.length === 0 ? (
          <div className="row mx-0 justify-content-center">
            <div className="col-12">
              <p
                className="col card py-2 px-3 shadow mt-3"
                style={{ fontSize: "14px", borderRadius: "10px" }}
              >
                Al momento non ci sono finanziamenti per questo contatto.
                Aggiungine uno nuovo tramite l'apposito tasto.
              </p>
            </div>
          </div>
        ) : (
          <div>
            <ul className=" p-0 my-2 mx-3">
              {props.info
                .sort((a, b) =>
                  a.data_decorrenza < b.data_decorrenza ? 1 : -1
                )
                .map((finanziamento) => (
                  <FinanziamentiItem
                    mostraDettagli={true}
                    finanziamento={finanziamento}
                    key={finanziamento.id_finanziamento}
                  />
                ))}
            </ul>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default FinanziamentiList;
