import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Swal from "sweetalert2";
import authService from "../../services/authService";
import { useHttpClient } from "../../util/http-hook";
import CollaboratoreForm from "./CollaboratoreForm";


const InformazioniCollaboratore = (props) => {
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();

  const onSubmit = async (values) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BASE_URL}/collaboratori/${props.info.id_collaboratore}`,
        "PATCH",
        JSON.stringify(values),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      Swal.fire({
        icon: "success",
        title: "Collaboratore modificato correttamente",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Qualcosa è andato storto...",
        text: error,
      });
    }
  };

  return (
    <CollaboratoreForm
      initialData={props.info}
      onSubmit={onSubmit}
      submitText={"Salva modifiche"}
      submitIcon={<FontAwesomeIcon icon={faSave} />}
    />
  );
};

export default InformazioniCollaboratore;
