import React, { useEffect, useState } from "react";
import { Dropdown, Form, Button, Modal } from "semantic-ui-react";
import { useFormik } from "formik";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useHttpClient } from "../../util/http-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import authService from "../../services/authService";
import lista_province from "../../services/province.json";
import lista_comuni from "../../services/comuni.json";

const tipo = [
  { key: 1, value: "Cliente", text: "Cliente" },
  { key: 2, value: "Lead", text: "Lead" },
];

const clienteSchema = Yup.object().shape({
  nome: Yup.string().required(),
  cognome: Yup.string().required(),
  amministrazione: Yup.number().required(),
  tipo: Yup.string().required(),
});

const AggiungiContatto = (props) => {
  const { sendRequest } = useHttpClient();
  const [dataNascita, setDataNascita] = useState(new Date());
  const [dataAssunzione, setDataAssunzione] = useState(new Date());
  const [amministrazioni, setAmministrazioni] = useState();
  const [comuni, setComuni] = useState();
  const token = authService.getCurrentToken();

  useEffect(() => {
    const ottieniAmministrazioni = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/amministrazioni/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const amministrazioni_response = [];
        responseData.forEach((amministrazione) => {
          amministrazioni_response.push({
            key: amministrazione.id_amministrazione,
            value: amministrazione.id_amministrazione,
            text: amministrazione.denominazione,
          });
        });
        setAmministrazioni(amministrazioni_response);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    ottieniAmministrazioni();
  }, [sendRequest, token]);

  const handleChangeProvincia = (data) => {
    formik.setFieldValue("provincia_residenza", data.value);
    lista_comuni.forEach((provincia) => {
      if (provincia.id === data.value) {
        setComuni(provincia.comuni);
      }
    });
  };

  const handleChange = (e) => {
    const split = e.target.value.split(" ");
    let value2 = "";

    const value1 =
      split[0].charAt(0).toUpperCase() + split[0].slice(1).toLowerCase();

    if (split[1]) {
      value2 =
        split[1].charAt(0).toUpperCase() + split[1].slice(1).toLowerCase();
    }

    formik.setFieldValue(e.target.id, value1 + " " + value2);
  };

  const formik = useFormik({
    initialValues: {
      cf: "",
      nome: "",
      cognome: "",
      citta_nascita: "",
      citta_residenza: "",
      indirizzo_residenza: "",
      provincia_residenza: "",
      cap_residenza: "",
      cellulare_1: "",
      appartenenza_cellulare_1: "",
      cellulare_2: "",
      appartenenza_cellulare_2: "",
      telefono_casa: "",
      telefono_ufficio: "",
      email: "",
      amministrazione: "",
      sede_lavorativa: "",
      tipo: "Cliente",
      numero_chiave_pensione: "",
    },
    validationSchema: clienteSchema,
    onSubmit: async (values) => {
      try {
        let trovato = 0;
        props.contatti.forEach((contatto) => {
          if (
            values.nome === contatto.nome &&
            values.cognome === contatto.cognome
          ) {
            trovato = 1;
            Swal.fire({
              icon: "question",
              title:
                "Esiste già un contatto con questo nome e cognome, vuoi continuare?",
              cancelButtonText: "Cancella",
              confirmButtonText: "Aggiungi comunque",
              showCancelButton: true,
            }).then(async (value) => {
              if (value.isConfirmed) {
                const responseData = await sendRequest(
                  process.env.REACT_APP_BASE_URL +
                    "/contatti/aggiungiContatto/",
                  "POST",
                  JSON.stringify({
                    cf: values.cf,
                    nome: values.nome,
                    cognome: values.cognome,
                    data_nascita: dataNascita,
                    citta_nascita: values.citta_nascita,
                    citta_residenza: values.citta_residenza,
                    provincia_residenza: values.provincia_residenza,
                    indirizzo_residenza: values.indirizzo_residenza,
                    cap_residenza: values.cap_residenza,
                    cellulare_1: values.cellulare_1,
                    appartenenza_cellulare_1: values.appartenenza_cellulare_1,
                    cellulare_2: values.cellulare_2,
                    appartenenza_cellulare_2: values.appartenenza_cellulare_2,
                    telefono_casa: values.telefono_casa,
                    telefono_ufficio: values.telefono_ufficio,
                    email: values.email,
                    tipo: values.tipo,
                    ref_amministrazione: values.amministrazione,
                    numero_chiave_pensione: values.numero_chiave_pensione,
                    sede_lavorativa: values.sede_lavorativa,
                    data_assunzione: dataAssunzione,
                  }),
                  {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                  }
                );
                Swal.fire({
                  icon: "success",
                  title: "Operazione completata!",
                  text: "Contatto inserito correttamente!",
                }).then(() => {
                  props.handleClose();
                  props.aggiungiContatto(responseData);
                });
              }
            });
          }
        });

        if (trovato === 0) {
          const responseData = await sendRequest(
            process.env.REACT_APP_BASE_URL + "/contatti/aggiungiContatto/",
            "POST",
            JSON.stringify({
              cf: values.cf,
              nome: values.nome,
              cognome: values.cognome,
              data_nascita: dataNascita,
              citta_nascita: values.citta_nascita,
              citta_residenza: values.citta_residenza,
              provincia_residenza: values.provincia_residenza,
              indirizzo_residenza: values.indirizzo_residenza,
              cap_residenza: values.cap_residenza,
              cellulare_1: values.cellulare_1,
              cellulare_2: values.cellulare_2,
              appartenenza_cellulare_1: values.appartenenza_cellulare_1,
              appartenenza_cellulare_2: values.appartenenza_cellulare_2,
              telefono_casa: values.telefono_casa,
              telefono_ufficio: values.telefono_ufficio,
              email: values.email,
              tipo: values.tipo,
              ref_amministrazione: values.amministrazione,
              sede_lavorativa: values.sede_lavorativa,
              data_assunzione: dataAssunzione,
              numero_chiave_pensione: values.numero_chiave_pensione,
            }),
            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            }
          );
          Swal.fire({
            icon: "success",
            title: "Operazione completata!",
            text: "Contatto inserito correttamente!",
          }).then(() => {
            props.handleClose();
            props.aggiungiContatto(responseData);
          });
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err.message,
        });
      }
    },
  });

  return (
    <React.Fragment>
      <Modal
        onClose={() => props.handleClose()}
        open={true}
        closeIcon={{
          style: { top: "1.0535rem", right: "1rem" },
          color: "black",
          name: "close",
        }}
        style={{ position: "relative", height: "auto" }}
        closeOnDimmerClick={false}
      >
        <Modal.Header className="text-center">
          Aggiungi nuovo contatto
          <FontAwesomeIcon icon={faUser} className="ml-2" />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Amministrazione</label>
                <Dropdown
                  error={
                    formik.errors.amministrazione &&
                    formik.touched.amministrazione
                  }
                  placeholder="Amministrazione"
                  fluid
                  selection
                  search
                  options={amministrazioni}
                  onChange={(e, data) =>
                    formik.setFieldValue("amministrazione", data.value)
                  }
                  onBlur={() => formik.setFieldTouched("amministrazione")}
                />
              </Form.Field>
              <Form.Input
                label="Sede lavorativa"
                id="sede_lavorativa"
                value={formik.values.sede_lavorativa}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.sede_lavorativa &&
                  formik.touched.sede_lavorativa
                }
              />
              <Form.Field required>
                <label style={{ fontWeight: 700 }}>Data assunzione</label>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                >
                  <KeyboardDatePicker
                    fullWidth
                    clearable
                    cancelLabel="Cancella"
                    clearLabel="Annulla"
                    name="data"
                    value={dataAssunzione}
                    onChange={setDataAssunzione}
                    format={"DD-MM-YYYY"}
                  />
                </MuiPickersUtilsProvider>
              </Form.Field>
            </Form.Group>
            <Form.Input
              label="Codice fiscale"
              id="cf"
              value={formik.values.cf}
              onChange={(e, data) =>
                formik.setFieldValue("cf", data.value.toUpperCase())
              }
              onBlur={formik.handleBlur}
              error={formik.errors.cf && formik.touched.cf}
            />
            <Form.Group widths="equal">
              <Form.Input
                required
                label="Nome"
                id="nome"
                value={formik.values.nome}
                onChange={formik.handleChange}
                onBlur={(e) => handleChange(e)}
                error={formik.errors.nome && formik.touched.nome}
              />
              <Form.Input
                required
                label="Cognome"
                id="cognome"
                value={formik.values.cognome}
                onChange={formik.handleChange}
                onBlur={(e) => handleChange(e)}
                error={formik.errors.cognome && formik.touched.cognome}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Provincia</label>
                <Dropdown
                  error={formik.errors.provincia && formik.touched.provincia}
                  placeholder="Provincia"
                  fluid
                  selection
                  search
                  options={lista_province}
                  value={formik.values.provincia_residenza}
                  onChange={(e, data) => handleChangeProvincia(data)}
                  onBlur={() => formik.setFieldTouched("provincia")}
                />
              </Form.Field>
              <Form.Field>
                <label>Città</label>
                <Dropdown
                  error={formik.errors.citta && formik.touched.citta}
                  placeholder="Città"
                  fluid
                  selection
                  search
                  options={comuni}
                  onChange={(e, data) =>
                    formik.setFieldValue("citta_residenza", data.value)
                  }
                  onBlur={() => formik.setFieldTouched("citta")}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Indirizzo di residenza "
                id="indirizzo_residenza"
                value={formik.values.indirizzo_residenza}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.indirizzo_residenza &&
                  formik.touched.indirizzo_residenza
                }
              />
              <Form.Input
                label="CAP residenza"
                id="cap_residenza"
                value={formik.values.cap_residenza}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.cap_residenza && formik.touched.cap_residenza
                }
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required>
                <label style={{ fontWeight: 700 }}>Data di nascita</label>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                >
                  <KeyboardDatePicker
                    fullWidth
                    clearable
                    cancelLabel="Cancella"
                    clearLabel="Annulla"
                    name="data"
                    value={dataNascita}
                    onChange={setDataNascita}
                    format={"DD-MM-YYYY"}
                  />
                </MuiPickersUtilsProvider>
              </Form.Field>
              <Form.Input
                label="Città di nascita"
                id="citta_nascita"
                value={formik.values.citta_nascita}
                onChange={formik.handleChange}
                onBlur={(e) => handleChange(e)}
                error={
                  formik.errors.citta_nascita && formik.touched.citta_nascita
                }
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Input
                label="Cellulare 1"
                id="cellulare_1"
                value={formik.values.cellulare_1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Form.Input
                label="Appartenenza cellulare 1"
                id="appartenenza_cellulare_1"
                value={formik.values.appartenenza_cellulare_1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.appartenenza_cellulare_1 &&
                  formik.touched.appartenenza_cellulare_1
                }
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Cellulare 2"
                id="cellulare_2"
                value={formik.values.cellulare_2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.cellulare_2 && formik.touched.cellulare_2}
              />
              <Form.Input
                label="Appartenenza cellulare 2"
                id="appartenenza_cellulare_2"
                value={formik.values.appartenenza_cellulare_2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.appartenenza_cellulare_2 &&
                  formik.touched.appartenenza_cellulare_2
                }
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Telefono casa"
                id="telefono_casa"
                value={formik.values.telefono_casa}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Form.Input
                label="Telefono ufficio"
                id="telefono_ufficio"
                value={formik.values.telefono_ufficio}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.telefono_ufficio &&
                  formik.touched.telefono_ufficio
                }
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Email"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.email && formik.touched.email}
              />
              <Form.Field required>
                <label>Tipo</label>
                <Dropdown
                  error={formik.errors.tipo && formik.touched.tipo}
                  fluid
                  selection
                  search
                  options={tipo}
                  defaultValue="Cliente"
                  onChange={(e, data) =>
                    formik.setFieldValue("tipo", data.value)
                  }
                  onBlur={() => formik.setFieldTouched("tipo")}
                />
              </Form.Field>
            </Form.Group>
            <Form.Input
              label="Numero chiave pensione"
              id="numero_chiave_pensione"
              value={formik.values.numero_chiave_pensione}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.errors.numero_chiave_pensione &&
                formik.touched.numero_chiave_pensione
              }
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon="add circle"
            positive
            type="submit"
            content="Aggiungi cliente"
            onClick={formik.handleSubmit}
          />
        </Modal.Actions>
      </Modal>
    </React.Fragment>
  );
};

export default AggiungiContatto;
