import {
  faFileContract,
  faPlusCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";
import ReactPaginate from "react-paginate";
import "../../App.css";

import IterPraticaList from "../components/IterPraticaList";
import authService from "../../services/authService";
import Loader from "react-loader-spinner";
import IterPraticaForm from "../components/IterPraticaForm";

const PaginaIterPratiche = () => {
  const { sendRequest, isLoading } = useHttpClient();
  const [open, setOpen] = useState(false);
  const token = authService.getCurrentToken();
  const [iterPratiche, setIterPratiche] = useState();
  const [risultatiRicerca, setRisultati] = useState();

  //Paginazione
  const [numeroPagine, setNumeroPagine] = useState();
  const [offset, setOffset] = useState(0);
  const [paginaSelezionata, setPaginaSelezionata] = useState(0);
  const elementiPerPagina = 10;

  const handlePageChange = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * elementiPerPagina;

    setPaginaSelezionata(selectedPage);
    setOffset(offset);
  };

  //Fine paginazione

  const formik = useFormik({
    initialValues: {
      ricerca: "",
    },
  });

  const aggiungiIterPratica = (iterPratica) => {
    setIterPratiche((iterPratiche) => [...iterPratiche, iterPratica]);
  };

  const handleSubmit = async (values) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BASE_URL + "/iterPratiche/",
        "POST",
        JSON.stringify({
          ...values,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      Swal.fire({
        icon: "success",
        title: "Operazione completata!",
        text: "Iter Pratica inserita correttamente!",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        aggiungiIterPratica(responseData);
        setOpen(false);
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Qualcosa è andato storto...",
        text: err,
      });
    }
  };

  useEffect(() => {
    const ottieniIterPratiche = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/iterPratiche/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );

        setIterPratiche(responseData);
        setNumeroPagine(Math.ceil(responseData.length / elementiPerPagina));
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    ottieniIterPratiche();
  }, [sendRequest, token]);

  useEffect(() => {
    if (iterPratiche) {
      const risultati = iterPratiche
        .filter(
          (iterPratica) =>
            iterPratica.contatto &&
            iterPratica.contatto
              .toLowerCase()
              .includes(formik.values.ricerca.toLowerCase())
        )
        .sort((a, b) => (a.createdat > b.createdat ? 1 : -1));

      if (risultati.length) {
        setNumeroPagine(Math.ceil(risultati.length / elementiPerPagina));
      }

      if (formik.values.ricerca) {
        const pagina = {};
        pagina.selected = 0;
        handlePageChange(pagina);
      }

      const risultatiPagina = risultati.slice(
        offset,
        offset + elementiPerPagina
      );
      setRisultati(risultatiPagina);
    }
  }, [formik.values.ricerca, iterPratiche, offset]);
  return (
    <React.Fragment>
      <IterPraticaForm
        onSave={handleSubmit}
        onClose={() => setOpen(false)}
        title={"Aggiungi nuova iter pratica"}
        open={open}
      />
      <div className="card my-2 mx-3" style={{ borderRadius: "10px" }}>
        <div
          className="card-header border-0"
          style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}
        >
          <h3 className="mb-0 text-center">Iter Pratiche</h3>
        </div>

        <div className="card-body">
          {authService.getCurrentRuolo() === "amministratore" && (
            <label
              className="float-left align-middle mt-2 col-md-6 col-lg-6 col-sm-12  p-0"
              style={{ fontWeight: 500 }}
              role="button"
              onClick={() => setOpen(true)}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
              Aggiungi iter pratica
            </label>
          )}

          <form>
            <div className="form-group row col-md-6 col-lg-6 col-sm-12 ">
              <label
                htmlFor="ricerca"
                className="col-sm-12 col-md-3 col-lg-3 mt-2 p-0 text-lg-right mr-1"
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className="mr-1  p-0"
                ></FontAwesomeIcon>
                Ricerca
              </label>
              <div className="col-sm-8 ml-auto p-0">
                <input
                  id="ricerca"
                  className="form-control text-center"
                  name="ricerca"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.ricerca}
                  style={{ borderRadius: "500rem" }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Loader
        type="ThreeDots"
        color="#385898"
        height={80}
        width={80}
        visible={isLoading}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      <ReactPaginate
        previousLabel={"<"}
        nextLabel={">"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={numeroPagine}
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
      {!isLoading && risultatiRicerca && (
        <IterPraticaList items={risultatiRicerca} />
      )}
    </React.Fragment>
  );
};

export default PaginaIterPratiche;
