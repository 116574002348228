import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Icon, TextArea } from "semantic-ui-react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useHttpClient } from "../../util/http-hook";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";
import authService from "../../services/authService";

moment.locale("it");

const opportunitaSchema = Yup.object().shape({
  ref_contatto: Yup.string().required("Richiesto"),
  ref_attivita: Yup.number().required("Richiesto"),
});

const AggiungiOpportunita = (props) => {
  const history = useHistory();
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();
  const [contatti, setContatti] = useState();
  const [attivita, setAttivita] = useState();
  const [finanziamenti, setFinanziamenti] = useState();
  const [selectedDate, setDate] = useState(new Date());

  useEffect(() => {
    const ottieniContatti = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/contatti/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const contatti_response = [];
        responseData.listaContatti.forEach((contatto) => {
          contatti_response.push({
            key: contatto.id_contatto,
            value: contatto.id_contatto,
            text: contatto.cognome + " " + contatto.nome,
          });
        });
        setContatti(contatti_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    const ottieniLead = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/contatti/ottieniLead",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const contatti_response = [];
        responseData.listaLead.forEach((contatto) => {
          contatti_response.push({
            key: contatto.id_contatto,
            value: contatto.id_contatto,
            text: contatto.cognome + " " + contatto.nome,
          });
        });
        setContatti(contatti_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    const ottieniAttivita = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/attivita/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const attivita_response = [];
        responseData.forEach((attivita) => {
          attivita_response.push({
            key: attivita.id_attivita,
            value: attivita.id_attivita,
            text: attivita.descrizione_attivita,
            icon: attivita.icona,
          });
        });
        setAttivita(attivita_response);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };
    ottieniAttivita();
    if (authService.getCurrentRuolo() === "amministratore") {
      ottieniContatti();
    }
    if (authService.getCurrentRuolo() === "collaboratore") {
      ottieniLead();
    }
  }, [sendRequest, token]);

  const informazioni = useFormik({
    initialValues: {
      ref_contatto: "",
      ref_attivita: "",
      note_opportunita: "",
      ref_finanziamento: "",
      data_opportunita: null,
    },
    validationSchema: opportunitaSchema,
    onSubmit: async (values) => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + `/opportunita/aggiungiOpportunita`,
          "POST",
          JSON.stringify({
            ref_contatto: values.ref_contatto,
            ref_attivita: values.ref_attivita,
            note_opportunita: encodeURIComponent(values.note_opportunita),
            ref_finanziamento: values.ref_finanziamento,
            data_opportunita: selectedDate,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );
        Swal.fire({
          icon: "success",
          title: "Operazione completata correttamente",
          text: "L'opportunità è stata caricata correttamente!",
        }).then(() => {
          props.handleClose();
          props.aggiungiOpportunita(responseData);
        });
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        }).then(() => history.go(0));
      }
    },
  });

  useEffect(() => {
    const ottieniFinanziamentiContatto = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL +
            "/finanziamenti/getFinanziamentiContatto",
          "POST",
          JSON.stringify({
            id_contatto: informazioni.values.ref_contatto,
          }),
          {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          }
        );
        const finanziamenti_contatto = [];
        responseData.forEach((finanziamento) => {
          finanziamenti_contatto.push({
            key: finanziamento.id_finanziamento,
            value: finanziamento.id_finanziamento,
            text:
              finanziamento.operazione.descrizione_operazione +
              " " +
              finanziamento.data_decorrenza,
          });
        });
        setFinanziamenti(finanziamenti_contatto);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: error,
        });
      }
    };

    if (informazioni.values.ref_contatto) {
      ottieniFinanziamentiContatto();
    }
  }, [informazioni.values.ref_contatto, sendRequest, token]);
  return (
    <React.Fragment>
      <div>
        <div style={{ fontSize: "110%" }}>
          <Form>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Contatto</label>
                <Dropdown
                  error={
                    informazioni.errors.ref_contatto &&
                    informazioni.touched.ref_contatto
                  }
                  placeholder="Contatto"
                  fluid
                  search
                  selection
                  value={informazioni.values.ref_contatto}
                  options={contatti}
                  onChange={(e, data) =>
                    informazioni.setFieldValue("ref_contatto", data.value)
                  }
                  onBlur={() => informazioni.setFieldTouched("ref_contatto")}
                />
              </Form.Field>
              <Form.Field required>
                <label>Attività</label>
                <Dropdown
                  error={
                    informazioni.errors.ref_attivita &&
                    informazioni.touched.ref_attivita
                  }
                  placeholder="Attività"
                  fluid
                  selection
                  value={informazioni.values.ref_attivita}
                  options={attivita}
                  onChange={(e, data) =>
                    informazioni.setFieldValue("ref_attivita", data.value)
                  }
                  onBlur={() => informazioni.setFieldTouched("ref_attivita")}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field className="mb-2" required>
                <label style={{ fontWeight: 700 }}>Data decorrenza</label>
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                  locale={"it"}
                >
                  <DateTimePicker
                    fullWidth
                    size="large"
                    ampm={false}
                    clearable
                    cancelLabel="Cancella"
                    clearLabel="Annulla"
                    id="data_opportunita"
                    value={selectedDate}
                    onBlur={informazioni.handleBlur}
                    onChange={setDate}
                    format="DD/MM/yyyy HH:mm"
                  />
                </MuiPickersUtilsProvider>
              </Form.Field>
              <Form.Field>
                <label>Finanziamento</label>
                <Dropdown
                  error={
                    informazioni.errors.ref_finanziamento &&
                    informazioni.touched.ref_finanziamento
                  }
                  disabled={!finanziamenti}
                  placeholder="Finanziamento"
                  fluid
                  selection
                  value={informazioni.values.ref_finanziamento}
                  options={finanziamenti}
                  onChange={(e, data) =>
                    informazioni.setFieldValue("ref_finanziamento", data.value)
                  }
                  onBlur={() =>
                    informazioni.setFieldTouched("ref_finanziamento")
                  }
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>Note</label>
              <TextArea
                id="note_opportunita"
                value={informazioni.values.note_opportunita}
                onChange={informazioni.handleChange}
                onBlur={informazioni.handleBlur}
              />
            </Form.Field>
            <Button
              onClick={informazioni.handleSubmit}
              positive
              icon
              type="submit"
              floated="right"
            >
              <Icon name="add circle" /> Aggiungi opportunità
            </Button>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AggiungiOpportunita;
