import {
  faArrowAltCircleLeft,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Menu, Segment } from "semantic-ui-react";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";

import NoteCollaboratoreList from "../components/DettaglioNote/NoteCollaboratoreList";
import InformazioniCollaboratore from "../components/InformazioniCollaboratore";
import FinanziamentiCollaboratoreList from "../components/DettagliFinanziamenti/FinanziamentiCollaboratoreList";
import authService from "../../services/authService";
import Loader from "react-loader-spinner";

const DettagliCollaboratore = (props) => {
  const { sendRequest, isLoading } = useHttpClient();
  const [activeItem, setActiveItem] = useState("informazioni");
  const token = authService.getCurrentToken();
  const history = useHistory();
  const [infoCollaboratore, setInfoCollaboratore] = useState();
  const ref_collaboratore = useParams().ref_collaboratore;

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  useEffect(() => {
    const ottieniInfo = async () => {
      try {
        const info = await sendRequest(
          process.env.REACT_APP_BASE_URL +
            `/collaboratori/${ref_collaboratore}`,
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        setInfoCollaboratore(info);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        }).then(() => {
          history.push("/");
        });
      }
    };
    ottieniInfo();
  }, [history, ref_collaboratore, sendRequest, token]);

  const eliminaCollaboratore = async function () {
    try {
      await sendRequest(
        process.env.REACT_APP_BASE_URL +
          `/collaboratori/eliminaCollaboratore/${ref_collaboratore}`,
        "DELETE",
        null,
        {
          Authorization: "Bearer " + token,
        }
      );
      Swal.fire({
        icon: "success",
        title: "Collaboratore eliminato correttamente!",
      }).then(() => {
        history.goBack();
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Qualcosa è andato storto...",
        text: error,
      });
    }
  };

  return (
    <React.Fragment>
      <Loader
        type="ThreeDots"
        color="#385898"
        height={80}
        width={80}
        visible={isLoading}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      {!isLoading && infoCollaboratore && (
        <div className="card my-2 mx-3">
          <div>
            <div
              className="card-header border-0 flex d-flex justify-content-between"
              style={{
                borderTopRightRadius: "10px",
                borderTopLeftRadius: "10px",
              }}
            >
              <span style={{ fontWeight: 600, fontSize: "120%" }}>
                {infoCollaboratore.informazioni.cognome}{" "}
                {infoCollaboratore.informazioni.nome}
              </span>
              <span
                role="button"
                style={{ fontWeight: 600 }}
                onClick={eliminaCollaboratore}
              >
                <FontAwesomeIcon icon={faTrash} /> Elimina
              </span>
            </div>

            <div className="card-body">
              <div>
                <span
                  role="button"
                  style={{ fontWeight: 600 }}
                  onClick={() => history.goBack()}
                >
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} /> Indietro
                </span>
              </div>
              <Menu tabular stackable>
                <Menu.Item
                  name="informazioni"
                  active={activeItem === "informazioni"}
                  onClick={handleItemClick}
                  color="blue"
                />
                <Menu.Item
                  name="finanziamenti"
                  active={activeItem === "finanziamenti"}
                  onClick={handleItemClick}
                  color="blue"
                />
                <Menu.Item
                  name="note"
                  active={activeItem === "note"}
                  onClick={handleItemClick}
                  color="blue"
                />
              </Menu>
              <Segment attached="bottom">
                <div className="row"></div>
                {activeItem === "informazioni" && (
                  <InformazioniCollaboratore
                    info={infoCollaboratore.informazioni}
                  />
                )}
                {activeItem === "note" && (
                  <NoteCollaboratoreList
                    note={infoCollaboratore.note}
                    ref_collaboratore={
                      infoCollaboratore.informazioni.id_collaboratore
                    }
                  />
                )}
                {activeItem === "finanziamenti" && (
                  <FinanziamentiCollaboratoreList
                    finanziamenti={infoCollaboratore.finanziamenti}
                    ref_collaboratore={
                      infoCollaboratore.informazioni.id_collaboratore
                    }
                  />
                )}
              </Segment>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DettagliCollaboratore;
