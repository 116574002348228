import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/it";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Dropdown, Form, Modal, Button } from "semantic-ui-react";
import { useHttpClient } from "../../util/http-hook";
import authService from "../../services/authService";
import { faEdit, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";

// Define your validation schema (adjust as needed)
const iterPraticaSchema = Yup.object().shape({
  contatto: Yup.string().required("Il contatto è richiesto"),
  amministrazione_contatto: Yup.string().required(
    "L'amministrazione del contatto è richiesta"
  ),
  ref_operazione: Yup.string().required("L'operazione è richiesta"),
  ref_collaboratore: Yup.string().required("Il collaboratore è richiesto"),
});

const IterPraticaForm = ({
  initialData = {},
  onSave,
  onClose,
  title,
  open,
}) => {
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();
  const [istituti, setIstituti] = useState([]);
  const [operazioni, setOperazioni] = useState([]);
  const [collaboratori, setCollaboratori] = useState([]);
  const history = useHistory();

  const eliminaIterPratica = async () => {
    Swal.fire({
      icon: "question",
      title: "Cancellare l'iter pratica?",
      text: "Cancellando l'iter pratica tutti i dati della pratica verranno cancellati, continuare?",
      confirmButtonText: "Elimina",
      showCancelButton: true,
      cancelButtonText: "Annulla",
      confirmButtonColor: "red",
    }).then(async (value) => {
      if (value.isConfirmed) {
        try {
          await sendRequest(
            process.env.REACT_APP_BASE_URL +
              `/iterPratiche/${initialData.id_iter_pratica}`,
            "DELETE",
            null,
            {
              Authorization: "Bearer " + token,
            }
          );
          Swal.fire({
            icon: "success",
            title: "Iter pratica eliminata correttamente!",
          });
          onClose();
          history.go(0);
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Qualcosa è andato storto...",
            text: error,
          });
        }
      }
    });
  };

  useEffect(() => {
    const fetchIstituti = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/istituti/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const istitutiOptions = responseData.map((istituto) => ({
          key: istituto.id_istituto,
          value: istituto.id_istituto,
          text: istituto.denominazione,
        }));
        setIstituti(istitutiOptions);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    fetchIstituti();
  }, [sendRequest, token]);

  useEffect(() => {
    const fetchCollaboratori = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/collaboratori/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        const collaboratoriOptions = responseData.map((collaboratore) => ({
          key: collaboratore.id_collaboratore,
          value: collaboratore.id_collaboratore,
          text: collaboratore.nome + " " + collaboratore.cognome,
        }));
        setCollaboratori(collaboratoriOptions);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    fetchCollaboratori();
  }, [sendRequest, token]);

  useEffect(() => {
    const fetchOperazioni = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/operazioni/",
          "GET",
          null,
          { Authorization: "Bearer " + token }
        );
        const operazioniOptions = responseData.map((operazione) => ({
          key: operazione.id_operazione,
          value: operazione.id_operazione,
          text: operazione.descrizione_operazione,
        }));
        setOperazioni(operazioniOptions);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Errore nel caricamento dei contatti",
          text: error.message || error,
        });
      }
    };

    fetchOperazioni();
  }, [sendRequest, token]);

  const formik = useFormik({
    initialValues: {
      contatto: initialData.contatto || "",
      amministrazione_contatto: initialData.amministrazione_contatto || "",
      ref_operazione: initialData.ref_operazione || "",
      ref_collaboratore: initialData.ref_collaboratore || "",
      data_decorrenza_pratica: initialData.data_decorrenza_pratica || null,
      data_richiesta_certificato_stipendio:
        initialData.data_richiesta_certificato_stipendio || null,
      richiedente_certificato_stipendio:
        initialData.richiedente_certificato_stipendio || "",
      data_rilascio_certificato_stipendio:
        initialData.data_rilascio_certificato_stipendio || null,
      data_richiesta_conteggio_estintivo:
        initialData.data_richiesta_conteggio_estintivo || null,
      data_rilascio_conteggio_estintivo:
        initialData.data_rilascio_conteggio_estintivo || null,
      istituto_conteggio_estintivo:
        initialData.istituto_conteggio_estintivo || null,
      data_caricamento_pratica: initialData.data_caricamento_pratica || null,
      data_delibera: initialData.data_delibera || null,
      acconto_richiesto: initialData.acconto_richiesto || null,
      data_richiesta_acconto: initialData.data_richiesta_acconto || null,
      data_acconto_erogato: initialData.data_acconto_erogato || null,
      data_richiesta_polizza: initialData.data_richiesta_polizza || null,
      data_rilascio_polizza: initialData.data_rilascio_polizza || null,
      data_notifica_inps_richiesta_priorita:
        initialData.data_notifica_inps_richiesta_priorita || null,
      data_rilascio_inps_richiesta_priorita:
        initialData.data_rilascio_inps_richiesta_priorita || null,
      data_richiesta_estinzione_anticipata_1:
        initialData.data_richiesta_estinzione_anticipata_1 || null,
      data_estinzione_anticipata_1:
        initialData.data_estinzione_anticipata_1 || null,
      data_richiesta_estinzione_anticipata_2:
        initialData.data_richiesta_estinzione_anticipata_2 || null,
      data_estinzione_anticipata_2:
        initialData.data_estinzione_anticipata_2 || null,
      data_invio_pec_estinzione_inps:
        initialData.data_invio_pec_estinzione_inps || null,
      data_rilascio_benestare_estinzione_inps:
        initialData.data_rilascio_benestare_estinzione_inps || null,
      data_notifica_telematica_inps_rilascio_benestare:
        initialData.data_notifica_telematica_inps_rilascio_benestare || null,
      data_rilascio_inps: initialData.data_rilascio_inps || null,
      data_notifica_pec_altre_atc:
        initialData.data_notifica_pec_altre_atc || null,
      data_rilascio_pec_altre_atc:
        initialData.data_rilascio_pec_altre_atc || null,
      data_richiesta_liquidazione:
        initialData.data_richiesta_liquidazione || null,
      data_liquidazione: initialData.data_liquidazione || null,
    },
    validationSchema: iterPraticaSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const formattedValues = {
          ...values,
        };
        await onSave(formattedValues);
        resetForm();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Errore",
          text: error.message || error,
        });
      }
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  return (
    <Modal
      open={open}
      style={{ position: "relative", height: "auto" }}
      closeOnDimmerClick={false}
      closeIcon={{
        style: { top: "1.0535rem", right: "1rem" },
        color: "black",
        name: "close",
      }}
      onClose={handleClose}
    >
      <Modal.Header>
        {title || "Gestione Iter Pratica"}

        <div className="float-right mr-5" style={{ fontSize: "70%" }}>
          {initialData.id_iter_pratica ? (
            authService.getCurrentRuolo() === "amministratore" && (
              <>
                <label
                  className="mr-3"
                  type="button"
                  onClick={eliminaIterPratica}
                >
                  <FontAwesomeIcon icon={faTrash} /> Elimina
                </label>
                <label
                  className="mr-0"
                  type="button"
                  onClick={formik.handleSubmit}
                >
                  <FontAwesomeIcon icon={faEdit} /> Modifica
                </label>
              </>
            )
          ) : (
            <label className="mr-0" type="button" onClick={formik.handleSubmit}>
              <FontAwesomeIcon icon={faSave} /> Salva
            </label>
          )}
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div style={{ fontSize: "110%" }}>
            <Form onSubmit={formik.handleSubmit}>
              <fieldset
                disabled={!authService.getCurrentRuolo() === "amministratore"}
              >
                <Form.Group widths="equal">
                  <Form.Field required>
                    <label>Cliente</label>
                    <Form.Input
                      type="text"
                      name="contatto"
                      value={formik.values.contatto}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.errors.contatto && formik.touched.contatto}
                    />
                    {formik.errors.contatto && formik.touched.contatto && (
                      <div style={{ color: "red", fontSize: "0.8em" }}>
                        {formik.errors.contatto}
                      </div>
                    )}
                  </Form.Field>
                  <Form.Field required>
                    <label>Amministrazione</label>
                    <Form.Input
                      type="text"
                      name="amministrazione_contatto"
                      value={formik.values.amministrazione_contatto}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.amministrazione_contatto &&
                        formik.touched.amministrazione_contatto
                      }
                    />
                    {formik.errors.amministrazione_contatto &&
                      formik.touched.amministrazione_contatto && (
                        <div style={{ color: "red", fontSize: "0.8em" }}>
                          {formik.errors.amministrazione_contatto}
                        </div>
                      )}
                  </Form.Field>
                  <Form.Field required>
                    <label>Operazione</label>
                    <Dropdown
                      disabled={
                        !authService.getCurrentRuolo() === "amministratore"
                      }
                      placeholder="Seleziona Operazione"
                      fluid
                      selection
                      search
                      options={operazioni}
                      value={formik.values.ref_operazione}
                      onChange={(e, data) =>
                        formik.setFieldValue("ref_operazione", data.value)
                      }
                      onBlur={() => formik.setFieldTouched("ref_operazione")}
                      error={
                        formik.errors.ref_operazione &&
                        formik.touched.ref_operazione
                      }
                    />
                    {formik.errors.ref_operazione &&
                      formik.touched.ref_operazione && (
                        <div style={{ color: "red", fontSize: "0.8em" }}>
                          {formik.errors.ref_operazione}
                        </div>
                      )}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Collaboratore</label>
                    <Dropdown
                      disabled={
                        !authService.getCurrentRuolo() === "amministratore"
                      }
                      placeholder="Seleziona collaboratore"
                      fluid
                      selection
                      search
                      options={collaboratori}
                      value={formik.values.ref_collaboratore}
                      onChange={(e, data) =>
                        formik.setFieldValue("ref_collaboratore", data.value)
                      }
                      onBlur={() => formik.setFieldTouched("ref_collaboratore")}
                      error={
                        formik.errors.ref_collaboratore &&
                        formik.touched.ref_collaboratore
                      }
                    />
                    {formik.errors.ref_collaboratore &&
                      formik.touched.ref_collaboratore && (
                        <div style={{ color: "red", fontSize: "0.8em" }}>
                          {formik.errors.ref_collaboratore}
                        </div>
                      )}
                  </Form.Field>
                  <Form.Field>
                    <label>Data decorrenza pratica</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_decorrenza_pratica"
                        value={formik.values.data_decorrenza_pratica}
                        onChange={(date) =>
                          formik.setFieldValue("data_decorrenza_pratica", date)
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data Richiesta certificato stipendio</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_richiesta_certificato_stipendio"
                        value={
                          formik.values.data_richiesta_certificato_stipendio
                        }
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_richiesta_certificato_stipendio",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Richiedente certificato stipendio</label>
                    <Dropdown
                      disabled={
                        !authService.getCurrentRuolo() === "amministratore"
                      }
                      placeholder="Seleziona richiedente"
                      fluid
                      selection
                      search
                      options={[
                        { key: "cliente", value: "cliente", text: "Cliente" },
                        { key: "fincrea", value: "fincrea", text: "Fincrea" },
                        {
                          key: "pec_fincrea",
                          value: "pec_fincrea",
                          text: "PEC Fincrea",
                        },
                      ]}
                      value={formik.values.richiedente_certificato_stipendio}
                      onChange={(e, data) =>
                        formik.setFieldValue(
                          "richiedente_certificato_stipendio",
                          data.value
                        )
                      }
                      onBlur={() =>
                        formik.setFieldTouched(
                          "richiedente_certificato_stipendio"
                        )
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Data Rilascio certificato stipendio</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_rilascio_certificato_stipendio"
                        value={
                          formik.values.data_rilascio_certificato_stipendio
                        }
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_rilascio_certificato_stipendio",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data richiesta conteggio estintivo</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_richiesta_conteggio_estintivo"
                        value={formik.values.data_richiesta_conteggio_estintivo}
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_richiesta_conteggio_estintivo",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Data rilascio conteggio estintivo</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_rilascio_conteggio_estintivo"
                        value={formik.values.data_rilascio_conteggio_estintivo}
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_rilascio_conteggio_estintivo",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Istituto Finanziario</label>
                    <Dropdown
                      disabled={
                        !authService.getCurrentRuolo() === "amministratore"
                      }
                      placeholder="Seleziona istituto finanziario"
                      fluid
                      selection
                      search
                      options={istituti}
                      value={formik.values.istituto_conteggio_estintivo}
                      onChange={(e, data) =>
                        formik.setFieldValue(
                          "istituto_conteggio_estintivo",
                          data.value
                        )
                      }
                      onBlur={() =>
                        formik.setFieldTouched("istituto_conteggio_estintivo")
                      }
                      error={
                        formik.errors.istituto_conteggio_estintivo &&
                        formik.touched.istituto_conteggio_estintivo
                      }
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data caricamento pratica</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_caricamento_pratica"
                        value={formik.values.data_caricamento_pratica}
                        onChange={(date) =>
                          formik.setFieldValue("data_caricamento_pratica", date)
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Data delibera</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_delibera"
                        value={formik.values.data_delibera}
                        onChange={(date) =>
                          formik.setFieldValue("data_delibera", date)
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>

                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Acconto richiesto</label>
                    <Form.Input
                      type="number"
                      name="acconto_richiesto"
                      value={formik.values.acconto_richiesto}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.acconto_richiesto &&
                        formik.touched.acconto_richiesto
                      }
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Data richiesta acconto</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_richiesta_acconto"
                        value={formik.values.data_richiesta_acconto}
                        onChange={(date) =>
                          formik.setFieldValue("data_richiesta_acconto", date)
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Data erogazione acconto</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_acconto_erogato"
                        value={formik.values.data_acconto_erogato}
                        onChange={(date) =>
                          formik.setFieldValue("data_acconto_erogato", date)
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data richiesta polizza</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_richiesta_polizza"
                        value={formik.values.data_richiesta_polizza}
                        onChange={(date) =>
                          formik.setFieldValue("data_richiesta_polizza", date)
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Data rilascio polizza</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_rilascio_polizza"
                        value={formik.values.data_rilascio_polizza}
                        onChange={(date) =>
                          formik.setFieldValue("data_rilascio_polizza", date)
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data notifica inps per priorità</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_notifica_inps_richiesta_priorita"
                        value={
                          formik.values.data_notifica_inps_richiesta_priorita
                        }
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_notifica_inps_richiesta_priorita",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Data rilascio inps priorità</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_rilascio_inps_richiesta_priorita"
                        value={
                          formik.values.data_rilascio_inps_richiesta_priorita
                        }
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_rilascio_inps_richiesta_priorita",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data richiesta estinzione anticipata 1</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_richiesta_estinzione_anticipata_1"
                        value={
                          formik.values.data_richiesta_estinzione_anticipata_1
                        }
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_richiesta_estinzione_anticipata_1",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Data estinzione 1</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_estinzione_anticipata_1"
                        value={formik.values.data_estinzione_anticipata_1}
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_estinzione_anticipata_1",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data richiesta estinzione anticipata 2</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_richiesta_estinzione_anticipata_2"
                        value={
                          formik.values.data_richiesta_estinzione_anticipata_2
                        }
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_richiesta_estinzione_anticipata_2",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Data estinzione 2</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_estinzione_anticipata_2"
                        value={formik.values.data_estinzione_anticipata_2}
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_estinzione_anticipata_2",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data invio PEC avvenuta estinzione INPS</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_invio_pec_estinzione_inps"
                        value={formik.values.data_invio_pec_estinzione_inps}
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_invio_pec_estinzione_inps",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>
                      Data rilascio benestare avvenuta estinzione INPS
                    </label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_rilascio_benestare_estinzione_inps"
                        value={
                          formik.values.data_rilascio_benestare_estinzione_inps
                        }
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_rilascio_benestare_estinzione_inps",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data notifica a INPS per rilascio benestare</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_notifica_telematica_inps_rilascio_benestare"
                        value={
                          formik.values
                            .data_notifica_telematica_inps_rilascio_benestare
                        }
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_notifica_telematica_inps_rilascio_benestare",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Data rilascio INPS</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_rilascio_inps"
                        value={formik.values.data_rilascio_inps}
                        onChange={(date) =>
                          formik.setFieldValue("data_rilascio_inps", date)
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data notifica PEC altre ATC</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_notifica_pec_altre_atc"
                        value={formik.values.data_notifica_pec_altre_atc}
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_notifica_pec_altre_atc",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Data rilascio benestare altre ATC</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_rilascio_pec_altre_atc"
                        value={formik.values.data_rilascio_pec_altre_atc}
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_rilascio_pec_altre_atc",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data richiesta liquidazione</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_richiesta_liquidazione"
                        value={formik.values.data_richiesta_liquidazione}
                        onChange={(date) =>
                          formik.setFieldValue(
                            "data_richiesta_liquidazione",
                            date
                          )
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                  <Form.Field>
                    <label>Data liquidazione</label>
                    <MuiPickersUtilsProvider
                      utils={MomentUtils}
                      libInstance={moment}
                    >
                      <KeyboardDatePicker
                        fullWidth
                        clearable
                        cancelLabel="Cancella"
                        clearLabel="Annulla"
                        name="data_liquidazione"
                        value={formik.values.data_liquidazione}
                        onChange={(date) =>
                          formik.setFieldValue("data_liquidazione", date)
                        }
                        format="DD-MM-YYYY"
                      />
                    </MuiPickersUtilsProvider>
                  </Form.Field>
                </Form.Group>
              </fieldset>
            </Form>
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default IterPraticaForm;
