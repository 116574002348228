import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import { Card, Container, Divider, Grid, Header } from "semantic-ui-react";
import moment from "moment";

export default class StampaContatto extends Component {
  render() {
    const { infoContatto } = this.props;

    return (
      <Container style={{ margin: "0 auto", maxWidth: "90%", padding: "1em" }}>
        <Card fluid>
          <Card.Content>
            {/* Header with full name */}
            <Card.Header style={{ fontSize: "1.8em", marginBottom: "0.5em" }}>
              {infoContatto.infoPersonali.cognome}{" "}
              {infoContatto.infoPersonali.nome}
            </Card.Header>
            <Divider />

            {/* Personal Contact Data */}
            <Header as="h3" dividing>
              Informazioni Personali
            </Header>
            <Grid style={{ margin: "1em" }} columns={2} stackable>
              <Grid.Row>
                <Grid.Column>
                  <p>
                    <strong>CF:</strong> {infoContatto.infoPersonali.cf}
                  </p>
                  <p>
                    <strong>Data di Nascita:</strong>{" "}
                    {moment(infoContatto.infoPersonali.data_nascita).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                  <p>
                    <strong>Città di Nascita:</strong>{" "}
                    {infoContatto.infoPersonali.citta_nascita}
                  </p>
                  <p>
                    <strong>Cellulare 1:</strong>{" "}
                    {infoContatto.infoPersonali.cellulare_1}
                  </p>
                  <p>
                    <strong>Telefono Casa:</strong>{" "}
                    {infoContatto.infoPersonali.telefono_casa}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <p>
                    <strong>Indirizzo:</strong>{" "}
                    {infoContatto.infoPersonali.indirizzo_residenza}
                  </p>
                  <p>
                    <strong>Città di Residenza:</strong>{" "}
                    {infoContatto.infoPersonali.citta_residenza}
                  </p>
                  <p>
                    <strong>Provincia:</strong>{" "}
                    {infoContatto.infoPersonali.provincia_residenza}
                  </p>
                  <p>
                    <strong>CAP:</strong>{" "}
                    {infoContatto.infoPersonali.cap_residenza}
                  </p>
                  <p>
                    <strong>Email:</strong> {infoContatto.infoPersonali.email}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />

            {/* Work (Amministrazione) Data */}
            <Header as="h3" dividing>
              Informazioni Amministrazione
            </Header>
            <Grid style={{ margin: "1em" }} columns={3} stackable>
              <Grid.Row>
                <Grid.Column>
                  <p>
                    <strong>Denominazione:</strong>{" "}
                    {infoContatto.infoLavorative.denominazione}
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <p>
                    <strong>Tipologia:</strong>{" "}
                    {
                      infoContatto.infoLavorative.tipologia_amministrazione
                        .descrizione_tipologia
                    }
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <p>
                    <strong>Data Assunzione:</strong>{" "}
                    {moment(infoContatto.infoPersonali.data_assunzione).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />

            {/* Active Finanziamenti */}
            <Header as="h3" dividing>
              Finanziamenti
            </Header>
            {infoContatto.infoFinanziamenti.length > 0 ? (
              <Card.Group itemsPerRow={3} stackable>
                {infoContatto.infoFinanziamenti
                  .sort((a, b) =>
                    a.data_decorrenza < b.data_decorrenza ? 1 : -1
                  )
                  .map((finanziamento) => (
                    <Card
                      key={finanziamento.id_finanziamento}
                      style={{ border: "0.5px solid grey" }}
                    >
                      <Card.Content>
                        <Card.Header>
                          <div className="text-center">
                            {finanziamento.operazione.descrizione_operazione ||
                              "Finanziamento"}
                            {finanziamento.tipologia === "nuovo" && (
                              <span className="ml-3">
                                <FontAwesomeIcon
                                  style={{ color: "#385898" }}
                                  icon={faStar}
                                />
                                <label
                                  style={{ fontWeight: 600 }}
                                  className="ml-1"
                                >
                                  Fincrea
                                </label>{" "}
                              </span>
                            )}
                          </div>
                        </Card.Header>
                        <Card.Description>
                          <Grid style={{ margin: "1em" }} columns={2} stackable>
                            <Grid.Row>
                              <Grid.Column>
                                <p>
                                  <strong>Rata stipulata:</strong>{" "}
                                  {finanziamento.rata_stipulata}
                                </p>

                                <p>
                                  <strong>Data decorrenza:</strong>{" "}
                                  {finanziamento.data_decorrenza}
                                </p>
                                {finanziamento.collaboratore && (
                                  <p>
                                    <strong>Collaboratore:</strong>{" "}
                                    {finanziamento.collaboratore.cognome}{" "}
                                    {finanziamento.collaboratore.nome}
                                  </p>
                                )}
                              </Grid.Column>
                              <Grid.Column>
                                <p>
                                  <strong>Società:</strong>{" "}
                                  {
                                    finanziamento.istituto_finanziario
                                      .denominazione
                                  }
                                </p>
                                <p>
                                  <strong>Data scadenza:</strong>{" "}
                                  {finanziamento.data_scadenza_finanziamento}
                                </p>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  ))}
              </Card.Group>
            ) : (
              <p>Nessun finanziamento.</p>
            )}
          </Card.Content>
        </Card>
      </Container>
    );
  }
}
