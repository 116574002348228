import {
  faPlusCircle,
  faSearch,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Modal } from "semantic-ui-react";
import Swal from "sweetalert2";
import { useHttpClient } from "../../util/http-hook";
import { useFormik } from "formik";

import CollaboratoriList from "../components/CollaboratoriList";
import authService from "../../services/authService";
import Loader from "react-loader-spinner";
import CollaboratoreForm from "../components/CollaboratoreForm";

const PaginaCollaboratori = () => {
  const { sendRequest, isLoading } = useHttpClient();
  const [open, setOpen] = useState(false);
  const [collaboratori, setCollaboratori] = useState();
  const [risultatiRicerca, setRisultati] = useState();
  const token = authService.getCurrentToken();

  const formik = useFormik({
    initialValues: {
      ricerca: "",
    },
  });

  const aggiungiCollaboratore = (collaboratore) => {
    setCollaboratori((collaboratori) => [...collaboratori, collaboratore]);
  };

  const onSubmit = async (values) => {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BASE_URL + "/collaboratori/",
        "POST",
        JSON.stringify(...values),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      Swal.fire({
        icon: "success",
        title: "Collaboratore aggiunto correttamente",
      }).then(() => {
        aggiungiCollaboratore(responseData);
        setOpen(false);
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Qualcosa è andato storto...",
        text: error,
      });
    }
  };

  useEffect(() => {
    const ottieniCollaboratori = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/collaboratori/",
          "GET",
          null,
          {
            Authorization: "Bearer " + token,
          }
        );
        setCollaboratori(responseData);
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Qualcosa è andato storto...",
          text: err,
        });
      }
    };
    ottieniCollaboratori();
  }, [sendRequest, token]);

  useEffect(() => {
    if (collaboratori) {
      const risultati = collaboratori.filter(
        (collaboratore) =>
          collaboratore.cognome &&
          collaboratore.cognome
            .toLowerCase()
            .includes(formik.values.ricerca.toLowerCase())
      );
      setRisultati(risultati);
    }
  }, [formik.values.ricerca, collaboratori]);
  return (
    <React.Fragment>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        style={{ position: "relative", height: "auto" }}
      >
        <Modal.Header className="text-center">
          Aggiungi nuovo collaboratore <FontAwesomeIcon icon={faUsers} />{" "}
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <CollaboratoreForm
              onSubmit={onSubmit}
              submitText={"Aggiungi collaboratore"}
              submitIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
      <div className="card my-2 mx-3" style={{ borderRadius: "10px" }}>
        <div
          className="card-header border-0"
          style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}
        >
          <h3 className="mb-0 text-center">Collaboratori</h3>
        </div>

        <div className="card-body">
          <label
            className="float-left align-middle mt-2 col-md-6 col-lg-6 col-sm-12  p-0"
            style={{ fontWeight: 500 }}
            role="button"
            onClick={() => setOpen(true)}
          >
            <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
            Aggiungi collaboratore
          </label>
          <form>
            <div className="form-group row col-md-6 col-lg-6 col-sm-12 ">
              <label
                htmlFor="ricerca"
                className="col-sm-12 col-md-3 col-lg-3 mt-2 p-0 text-lg-right mr-1"
              >
                <FontAwesomeIcon
                  icon={faSearch}
                  className="mr-1  p-0"
                ></FontAwesomeIcon>
                Ricerca
              </label>
              <div className="col-sm-8 ml-auto p-0">
                <input
                  id="ricerca"
                  className="form-control text-center"
                  name="ricerca"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.ricerca}
                  style={{ borderRadius: "500rem" }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <Loader
        type="ThreeDots"
        color="#385898"
        height={80}
        width={80}
        visible={isLoading}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
      {!isLoading && risultatiRicerca && (
        <CollaboratoriList items={risultatiRicerca} />
      )}
    </React.Fragment>
  );
};

export default PaginaCollaboratori;
