import React from "react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faEdit,
  faMoneyBill,
  faUniversity,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

import IterPraticaForm from "./IterPraticaForm";
import authService from "../../services/authService";
import { useHttpClient } from "../../util/http-hook";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const IterPraticaItem = (props) => {
  const [open, setOpen] = React.useState(false);
  const { sendRequest, isLoading } = useHttpClient();
  const history = useHistory();
  const token = authService.getCurrentToken();

  const handleSubmit = async (values) => {
    try {
      await sendRequest(
        process.env.REACT_APP_BASE_URL +
          `/iterPratiche/${props.iterPratica.id_iter_pratica}`,
        "PATCH",
        JSON.stringify({
          ...values,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      );
      Swal.fire({
        icon: "success",
        title: "Operazione completata!",
        text: "Iter Pratica modificata correttamente!",
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        setOpen(false);
        if (
          values.cliente ||
          values.amministrazione_contatto ||
          values.operazione ||
          values.data_caricamento_pratica ||
          values.data_delibera ||
          values.data_liquidazione
        ) {
          history.go(0);
        }
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Qualcosa è andato storto...",
        text: err,
      });
    }
  };
  return (
    <React.Fragment>
      {open && (
        <IterPraticaForm
          open={open}
          initialData={props.iterPratica}
          onSave={handleSubmit}
          title={"Modifica iter pratica"}
          F
          onClose={() => setOpen(false)}
          isUpdate={true}
        />
      )}
      <div
        className="card shadow col-sm-9 mx-auto mt-4"
        style={{ border: "none", borderRadius: "10px" }}
      >
        <div
          className="d-flex justify-content-between mt-2 mb-3"
          style={{ fontSize: "105%" }}
        >
          <div>
            <FontAwesomeIcon icon={faUser} />{" "}
            <span style={{ fontWeight: 700 }} className="ml-1">
              Cliente:
            </span>{" "}
            {props.iterPratica.contatto}
          </div>
          <div>
            <FontAwesomeIcon
              role="button"
              onClick={() => setOpen(true)}
              icon={faEdit}
              size="lg"
            />
          </div>
        </div>
        <div className="mb-3">
          <FontAwesomeIcon icon={faUsers} className="mr-2" />
          <span style={{ fontWeight: 700 }} className="ml-1">
            Collaboratore:
          </span>{" "}
          {props.iterPratica.collaboratore.nome}{" "}
          {props.iterPratica.collaboratore.cognome}
        </div>
        <div className="mb-3">
          <FontAwesomeIcon icon={faUniversity} className="mr-2" />
          <span style={{ fontWeight: 700 }} className="ml-1">
            Amministrazione:
          </span>{" "}
          {props.iterPratica.amministrazione_contatto}
        </div>

        <div className="mb-3">
          <FontAwesomeIcon icon={faMoneyBill} className="mr-2" />
          <span style={{ fontWeight: 700 }} className="ml-1">
            Operazione:
          </span>{" "}
          {props.iterPratica.operazione.descrizione_operazione}
        </div>

        {props.iterPratica.data_caricamento_pratica && (
          <div className="mb-3">
            <FontAwesomeIcon icon={faCalendar} className="mr-2" />
            <span style={{ fontWeight: 700 }} className="ml-1">
              Data caricamento pratica:
            </span>{" "}
            {props.iterPratica.data_caricamento_pratica}
          </div>
        )}

        {props.iterPratica.data_delibera && (
          <div className="mb-3">
            <FontAwesomeIcon icon={faCalendar} className="mr-2" />
            <span style={{ fontWeight: 700 }} className="ml-1">
              Data delibera:
            </span>{" "}
            {props.iterPratica.data_delibera}
          </div>
        )}

        {props.iterPratica.data_liquidazione && (
          <div className="mb-3">
            <FontAwesomeIcon icon={faCalendar} className="mr-2" />
            <span style={{ fontWeight: 700 }} className="ml-1">
              Data liquidazione:
            </span>{" "}
            {props.iterPratica.data_liquidazione}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default IterPraticaItem;
