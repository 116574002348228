import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArchive,
  faCalendarAlt,
  faCalendarTimes,
  faIdCard,
  faMoneyBill,
  faSearchPlus,
  faStar,
  faUniversity,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";
import InfoFinanziamento from "../../../shared/components/InfoFinanziamento";
import moment from "moment";

const FinanziamentiItem = (props) => {
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  return (
    <React.Fragment>
      {visible && (
        <InfoFinanziamento
          finanziamento={props.finanziamento}
          handleClose={handleClose}
        />
      )}

      <div
        className="card shadow col-sm-9 mx-auto mt-4"
        style={{ border: "none", borderRadius: "10px" }}
      >
        <div className="d-flex justify-content-between mb-3 mt-2">
          <div>
            <span style={{ fontWeight: 600, fontSize: "130%" }}>
              {props.finanziamento.operazione.descrizione_operazione}
            </span>
          </div>
          {props.mostraDettagli && (
            <div>
              <FontAwesomeIcon
                role="button"
                icon={faSearchPlus}
                size="lg"
                onClick={() => setVisible(true)}
              />
            </div>
          )}
        </div>
        {props.finanziamento.contatto && (
          <div className="mb-3">
            <FontAwesomeIcon icon={faIdCard} />{" "}
            <label style={{ fontWeight: 600 }} className="ml-1">
              Cliente:
            </label>{" "}
            {props.finanziamento.contatto.cognome}{" "}
            {props.finanziamento.contatto.nome}
          </div>
        )}
        {props.finanziamento.rata_stipulata && (
          <div className="mb-3">
            <FontAwesomeIcon icon={faMoneyBill} />{" "}
            <label style={{ fontWeight: 600 }} className="ml-1">
              Rata stipulata:
            </label>{" "}
            {props.finanziamento.rata_stipulata} €
          </div>
        )}
        {props.finanziamento.rata_stipulata && (
          <div className="mb-3">
            <FontAwesomeIcon icon={faUniversity} />{" "}
            <label style={{ fontWeight: 600 }} className="ml-1">
              Società:
            </label>{" "}
            {props.finanziamento.istituto_finanziario &&
              props.finanziamento.istituto_finanziario.denominazione}
          </div>
        )}

        <div className="pl-3 row mb-3">
          <div>
            <FontAwesomeIcon icon={faCalendarAlt} />{" "}
            <label style={{ fontWeight: 600 }} className="ml-1">
              Data decorrenza:
            </label>{" "}
            {moment(props.finanziamento.data_decorrenza).format("DD/MM/yyyy")}
          </div>
          <div className="ml-3">
            <FontAwesomeIcon icon={faCalendarTimes} />{" "}
            <label style={{ fontWeight: 600 }} className="ml-1">
              Data scadenza:
            </label>{" "}
            {moment(props.finanziamento.data_scadenza_finanziamento).format(
              "DD/MM/yyyy"
            )}
          </div>
        </div>

        <div className="d-lg-flex justify-content-between mb-2">
          {props.finanziamento.collaboratore && (
            <div className="mb-3 mb-lg-0">
              <FontAwesomeIcon icon={faUserCheck} />{" "}
              <label style={{ fontWeight: 600 }} className="ml-1">
                Collaboratore:
              </label>{" "}
              {props.finanziamento.collaboratore.cognome}{" "}
              {props.finanziamento.collaboratore.nome}
            </div>
          )}

          <div>
            {props.finanziamento.archiviato && (
              <>
                <FontAwesomeIcon
                  style={{ color: "#385898" }}
                  icon={faArchive}
                />
                <label style={{ fontWeight: 600 }} className="ml-1">
                  Archiviato
                </label>{" "}
              </>
            )}
            {props.finanziamento.tipologia === "nuovo" ? (
              <>
                <FontAwesomeIcon style={{ color: "#385898" }} icon={faStar} />
                <label style={{ fontWeight: 600 }} className="ml-1">
                  Fincrea
                </label>{" "}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FinanziamentiItem;
