import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import "moment/locale/it";
import { Dropdown, Form, } from "semantic-ui-react";
import { useHttpClient } from "../../util/http-hook";
import authService from "../../services/authService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Define your validation schema (adjust as needed)
const collaboratoriSchema = Yup.object().shape({
  cognome: Yup.string().required("Richiesto"),
  cellulare_1: Yup.string().required("Richiesto"),
});

const CollaboratoreForm = ({
  initialData = {},
  onSubmit,
  submitText,
  submitIcon,
}) => {
  const { sendRequest } = useHttpClient();
  const token = authService.getCurrentToken();
  const [userCollaboratori, setUserCollaboratori] = useState([]);

  useEffect(() => {
    const fetchUserCollaboratori = async () => {
      try {
        const responseData = await sendRequest(
          process.env.REACT_APP_BASE_URL + "/utenti/ottieniCollaboratori",
          "GET",
          null,
          { Authorization: "Bearer " + token }
        );
        const userCollaboratoriOptions = responseData.map(
          (user_collaboratore) => ({
            key: user_collaboratore.email,
            value: user_collaboratore.email,
            text: user_collaboratore.email,
          })
        );
        setUserCollaboratori(userCollaboratoriOptions);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Errore nel caricamento dei contatti",
          text: error.message || error,
        });
      }
    };

    fetchUserCollaboratori();
  }, [sendRequest, token]);

  const formik = useFormik({
    initialValues: {
      nome: initialData.nome || "",
      cognome: initialData.cognome || "",
      cellulare_1: initialData.cellulare_1 || "",
      cellulare_2: initialData.cellulare_2 || "",
      telefono_casa: initialData.telefono_casa || "",
      telefono_ufficio: initialData.telefono_ufficio || "",
      email: initialData.email || "",
      partita_iva: initialData.partita_iva || "",
      ref_utente: initialData.ref_utente || "",
    },
    validationSchema: collaboratoriSchema,
    onSubmit: async (values) => {
      onSubmit(values);
    },
  });

  return (
    <React.Fragment>
      <div className="row">
        <div className="row ml-auto mr-3 mb-2 mb-md-0 ">
          <label
            role="button"
            style={{ fontWeight: 600 }}
            onClick={formik.handleSubmit}
          >
            {submitIcon} {submitText}
          </label>
        </div>
      </div>
      <div style={{ fontSize: "110%" }}>
        <Form unstackable>
          <Form.Group widths="equal">
            <Form.Field required>
              <label>Utente</label>
              <Dropdown
                placeholder="Seleziona utente"
                fluid
                selection
                search
                options={userCollaboratori}
                value={formik.values.ref_utente}
                onChange={(e, data) =>
                  formik.setFieldValue("ref_utente", data.value)
                }
                onBlur={() => formik.setFieldTouched("ref_utente")}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Nome"
              id="nome"
              className="mb-3 mb-md-0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nome}
            />
            <Form.Input
              required
              label="Cognome"
              id="cognome"
              className="mb-3 mb-md-0"
              error={formik.errors.cognome && formik.touched.cognome}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cognome}
            />
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              required
              label="Cellulare 1"
              id="cellulare_1"
              className="mb-3 mb-md-0"
              error={formik.errors.cellulare_1 && formik.touched.cellulare_1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cellulare_1}
            />
            <Form.Input
              label="Cellulare 2"
              id="cellulare_2"
              className="mb-3 mb-md-0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cellulare_2}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Telefono casa"
              id="telefono_casa"
              className="mb-3 mb-md-0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.telefono_casa}
            />
            <Form.Input
              label="Telefono ufficio"
              id="telefono_ufficio"
              className="mb-3 mb-md-0"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.telefono_ufficio}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Email"
              id="email"
              className="mb-3 mb-md-0"
              error={formik.errors.email && formik.touched.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <Form.Input
              label="Partita IVA"
              id="partita_iva"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.partita_iva}
            />
          </Form.Group>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default CollaboratoreForm;
