import React from "react";

import IterPraticaItem from "./IterPraticaItem";
import "../components/IterPraticaForm";

const IterPraticaList = (props) => {

  return (
    <React.Fragment>
      
      <div>
        {props.items.length === 0 ? (
          <div className="row mx-0 justify-content-center">
            <div className="col-12">
              <p
                className="col card py-2 px-3 shadow mt-3"
                style={{ fontSize: "14px", borderRadius: "10px" }}
              >
                Al momento non sono presenti iter pratiche. Inseriscine una
                tramite l'apposito bottone.
              </p>
            </div>
          </div>
        ) : (
          <div>
            <ul className=" p-0 my-2 mx-3">
              {props.items 
                .sort((a, b) => (a.createdat < b.createdat ? 1 : -1))
                .map((iterPratica) => (
                  <IterPraticaItem
                    iterPratica={iterPratica}
                    key={iterPratica.id_iter_pratica}
                  />
                ))}
            </ul>
            <br></br>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default IterPraticaList;
