import React from "react";

import CollaboratoriItem from './CollaboratoriItem'

const CollaboratoriList = (props) => {
    if (props.items.length === 0) {
        return (
          <div className="row mx-0 justify-content-center">
            <div className="col-12">
              <p
                className="col card py-2 px-3 shadow mt-3"
                style={{ fontSize: "14px", borderRadius: "10px" }}
              >
                Al momento non ci sono collaboratori, aggiungi un nuovo collaboratore
                per visualizzarlo qui.
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <React.Fragment>
            <ul className=" p-0 my-2 mx-3">
              {props.items.sort((a,b) => (a.cognome > b.cognome ? 1 : -1)).map((collaboratore) => (
                <CollaboratoriItem
                  key={collaboratore.id_collaboratore}
                  collaboratore={collaboratore}
                />
              ))}
            </ul>
            <br></br>
          </React.Fragment>
        );
      }
};

export default CollaboratoriList;
